import { Component } from '@angular/core';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core/models';

interface CurrencyProps extends RcgFormlyFieldProps {
  maxDecimalPlaces?: number;
  minDecimalPlaces?: number;
}

@Component({
  selector: 'rcg-currency-field',
  template: `<rcg-currency-form-field
    [formControl]="formControl"
    [placeholder]="to.placeholder"
    [maxDecimalPlaces]="props.maxDecimalPlaces ?? 2"
    [minDecimalPlaces]="props.minDecimalPlaces ?? 2"
    [disabled]="to.disabled ?? false"
    [readonly]="to.readonly ?? false"
  >
  </rcg-currency-form-field>`,
})
export class CurrencyFieldComponent extends RcgFieldType<number | null, CurrencyProps> {}
