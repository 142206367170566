import { Component, Input, forwardRef, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IntlService } from '@rcg/intl';

@Component({
  selector: 'rcg-currency-form-field',
  template: `
    <input
      matInput
      type="text"
      [ngModel]="displayValue"
      (keydown)="onKeyDown($event)"
      (ngModelChange)="onInputChange($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
      [placeholder]="placeholder ?? ''"
      [disabled]="disabled ?? false"
      [readonly]="readonly ?? false"
    />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyFormFieldComponent),
      multi: true,
    },
  ],
})
export class CurrencyFormFieldComponent implements ControlValueAccessor {
  @Input() placeholder?: string = '';
  @Input() maxDecimalPlaces?: number = 2;
  @Input() minDecimalPlaces?: number = 2;
  @Input() disabled?: boolean = false;
  @Input() readonly?: boolean = false;

  displayValue: string = '';
  private value: number | null = null;
  private onTouched: () => void = () => {};
  private onChange: (value: number | null) => void = () => {};

  private intlService = inject(IntlService);
  private readonly locale = toSignal(this.intlService.locale$);

  writeValue(value: number | null): void {
    if (typeof value === 'string') {
      this.value = this.parseValue(value);
    } else {
      this.value = value;
    }
    this.updateDisplayValue();
  }

  registerOnChange(fn: (value: number | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onInputChange(value: string): void {
    if (this.disabled || this.readonly) return;
    this.value = this.parseValue(value);
    this.onChange(this.value);
  }

  onBlur(): void {
    if (this.disabled || this.readonly) return;
    this.onTouched();
    this.updateDisplayValue();
  }

  onFocus(): void {
    if (this.disabled || this.readonly) return;
    this.updateDisplayValue();
  }

  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    const allowedChars = /^[0-9]$/;
    const decimalSeparators = ['.', ','];

    if (allowedKeys.includes(event.key) || allowedChars.test(event.key)) {
      return;
    }

    if (decimalSeparators.includes(event.key)) {
      const inputElement = event.target as HTMLInputElement;
      const currentValue = inputElement.value;

      // Check if a decimal separator already exists
      if (decimalSeparators.some((separator) => currentValue.includes(separator))) {
        event.preventDefault();
        return;
      }
      return;
    }

    // Block all other keys
    event.preventDefault();
  }

  private updateDisplayValue(): void {
    if (this.value !== null) {
      this.displayValue = this.formatValueForInput(this.value);
    } else {
      this.displayValue = '';
    }
  }

  private parseValue(value: string): number | null {
    // Replace both comma and period with a period for parsing
    const normalizedValue = value.replace(/[.,]/g, '.');
    // Remove all non-numeric characters except the last period
    const cleanedValue = normalizedValue.replace(/[^0-9.]+/g, '').replace(/\.(?=.*\.)/g, '');
    const numericValue = parseFloat(cleanedValue);
    return isNaN(numericValue) ? null : numericValue;
  }

  private formatValueForInput(value: number): string {
    const locale = this.locale();
    let localeValue = 'sl-SI';

    if (locale) {
      localeValue = locale.includes('_') ? locale.replace('_', '-') : locale;
    }

    const formatter = new Intl.NumberFormat(localeValue, {
      style: 'decimal',
      minimumFractionDigits: this.minDecimalPlaces,
      maximumFractionDigits: 10, // must be set or will round number to 3 decimal places
    });

    return formatter.format(value);
  }
}
