import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function requiredFieldValidator(control: AbstractControl): ValidationErrors | null {
  return control.value ? null : { requiredField: true };
}

export function invalidAcObjectValidator(control: AbstractControl): ValidationErrors | null {
  if (!control?.value || control.value === '') {
    return null;
  }
  return typeof control.value !== 'object' /*|| control.value === null*/ ? { invalidAcObject: true } : null;
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(control.value) ? null : { email: true };
}

export function emailValidatorAllowEmpty(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(control.value) ? null : { email: true };
}

export function workingHoursDateRangeValidator(control: AbstractControl) {
  const { working_hours_from, working_hours_to } = control.value;

  if (!working_hours_from || !working_hours_to) {
    return null;
  }

  try {
    const from = Date.parse(working_hours_from!);
    const to = Date.parse(working_hours_to);

    if (isNaN(from) || isNaN(to)) {
      return null;
    }

    if (from >= to) {
      return { workingHoursDateRange: { message: 'Končni datum mora biti večji od začetnega' } };
    }
  } catch (error) {
    return null;
  }

  return null;
}

export function minTimeSeconds(control: AbstractControl, field: FormlyFieldConfig) {
  const value = control?.value;
  const minSeconds = field?.props?.settings?.minSeconds;
  if (!minSeconds) {
    return null;
  }

  if (!value || value < minSeconds) {
    return { 'min-time': { message: `Minimalni čas je ${minSeconds / 60} minut.` } };
  }
  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function contactFirstNameLastNameValidator(control: AbstractControl, _: FormlyFieldConfig, options: any) {
  const form = control?.value;

  if (!form) {
    return null;
  }

  const { first_name, last_name } = form;

  const normalizedFirstName = first_name && typeof first_name === 'object' && 'value' in first_name ? first_name.value : first_name;
  const normalizedLastName = last_name && typeof last_name === 'object' && 'value' in last_name ? last_name.value : last_name;

  if (!normalizedFirstName?.trim() && !normalizedLastName?.trim()) {
    return { 'contact-name': { message: `${options?.errorMessage ?? 'Vnesite vsaj eno od polj ime ali priimek'}` } };
  }
  return null;
}

export function currencyValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  const value = control?.value;

  if (value === null || value === undefined || value === '') {
    return null;
  }

  const maxDecimalPlaces = field?.props?.maxDecimalPlaces ?? 2;

  // Check if it's a valid number or decimal
  const numberRegex = /^\d+(\.\d+)?$/;
  if (!numberRegex.test(value.toString())) {
    return {
      currency: {
        message: 'Invalid number format.',
      },
    };
  }

  // Check if decimal part exceeds maxDecimalPlaces
  const [, decimalPart] = value.toString().split('.');
  if (decimalPart && decimalPart.length > maxDecimalPlaces) {
    return {
      currency: {
        actual: decimalPart.length,
        max: maxDecimalPlaces,
      },
    };
  }

  return null;
}
