<ng-container [ngSwitch]="props.headingType">
  <h1 *ngSwitchCase="'h1'">{{ val() }}</h1>
  <h2 *ngSwitchCase="'h2'">{{ val() }}</h2>
  <h3 *ngSwitchCase="'h3'">{{ val() }}</h3>
  <h4 *ngSwitchCase="'h4'">{{ val() }}</h4>
  <h5 *ngSwitchCase="'h5'">{{ val() }}</h5>
  <h6 *ngSwitchCase="'h6'">{{ val() }}</h6>
  <pre *ngSwitchCase="'pre'">{{ val() }}</pre>
  <p *ngSwitchDefault>{{ val() }}</p>
</ng-container>
